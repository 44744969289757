import api from '@/Api'
import mixins from '../../romaCommon.js'

const getDefaultState = () => {
  return {
    dailySummaryData: null,
    selectedDate: mixins.getJsDateNow_romaTZ(),
    filterActiveOnly: true,
    filterHideNoActivity: true, 
    error: null,
  }
}

const state = getDefaultState()

const mutations = {
  setError(state, error) {
    state.error = error
  },
  clearError(state) {
    state.error = null
  },
  setFilterActiveOnly(state, value) {
    state.filterActiveOnly = value
  },
  setFilterHideNoActivity(state,value) {
    state.filterHideNoActivity = value
  },
  setDailySummaryData(state, dailySummaryData) {
    state.dailySummaryData = dailySummaryData
  },
  setSelectedDate(state, selectedDate) {
    state.selectedDate = selectedDate
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  reportDate: state => {
    return state.dailySummaryData?.date
  },
  dailySummaryDataFiltered: state => {
    return state.dailySummaryData?.rows
  },
}

const actions = {
  async loadDailySummary({ commit,state }) {
    commit('setError', null)
    const params = { 
      date: mixins.formatJsDate_localTZ(state.selectedDate),
      activeOnly: state.filterActiveOnly,
      hideNoActivity: state.filterHideNoActivity,
    }
    return api.getDailySummary(params).then(response => {
      console.log("daily summary data loaded: ", response.data)
      commit('setDailySummaryData', response.data)
      return response
    })
    .catch(error => {
        console.log("failed to load Daily Summary data:", error)
        commit('setError', { 
          message: "Failed to load Daily Summary Data: ",
          reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
        });
    })
  },
  setSelectedDate({ commit, dispatch }, date) {
    commit('setSelectedDate', date)
    return dispatch('loadDailySummary')
  },
  setFilterActiveOnly({commit, dispatch}, value) {
    commit('setFilterActiveOnly', value)
    return dispatch('loadDailySummary')
  },
  setFilterHideNoActivity({commit, dispatch}, value) {
    commit('setFilterHideNoActivity', value)
    return dispatch('loadDailySummary')
  },
  reset({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}