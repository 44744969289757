import api from '@/Api'
import mixins from '../../romaCommon.js'

const tabIndexMap = { 3: 'win_loss', 2: 'turnover', 4: 'member_fee', 1: 'bets', 0: 'active_accounts'};

const getDefaultState = () => {
  return {
    activityData: [],
    filterCustomersOnly: true,
    filterHideClosed: true, 
    filterHideNoActivity: true,
    selectedReportIndex: 3,
    selectedDate: null,
    baseCurrencyMode: false,
    error: null,
  }
}

const state = getDefaultState();

const mutations = {
  setActivityData(state, activityData) {
    state.activityData = activityData
  },
  setError(state, error) {
    state.error = error
  },
  setFilterHideClosed(state,value) {
    state.filterHideClosed = value
  },
  setFilterCustomersOnly(state,value) {
    state.filterCustomersOnly = value
  },
  setFilterHideNoActivity(state,value) {
    state.filterHideNoActivity = value
  },
  setSelectedDate(state,selectedDate) {
    state.selectedDate = selectedDate
  },
  setSelectedReportIndex(state,value) {
    state.selectedReportIndex = value
  },
  setBaseCurrencyMode(state, value) {
    state.baseCurrencyMode = value
  },
  clearError(state) {
    state.error = null
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  selectedReportIndex: state => {
    return state.selectedReportIndex
  },
  activityDataFiltered: state => {
    return state.activityData?.rows
  },
  reportDate: state => {
    return state.activityData?.date
  },
  filterHideClosed: state => {
    return state.filterHideClosed
  },
  filterHideNoActivity: state => {
    return state.filterHideNoActivity
  },
  baseCurrencyMode: state => {
    return state.baseCurrencyMode
  },
  selectedDate: state => {
    return state.selectedDate
  },
  requestQueryParams: (state) => (format) => {
    return {
      date: mixins.formatJsDate_localTZ(state.selectedDate),
      type: tabIndexMap[state.selectedReportIndex],
      hideClosed: state.filterHideClosed,
      hideNoActivity: state.filterHideNoActivity,
      base: state.baseCurrencyMode,
      format: format,
    }
  },
}

const actions = {
  async loadSevenDayActivity({ commit, getters }) {
    commit('clearError')
    return api.getSevenDayActivity(getters.requestQueryParams()).then(response => {
      console.log("Seven Day Activity data loaded: ", response.data)
      commit('setActivityData', response.data)
      return response
    }).catch(error => {
      console.error("loadSevenDayActivity:", error)
      commit('setError', { 
        message: "Failed to seven day activity data",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    })
  },
  setFilterHideClosed({commit,dispatch}, value) {
    commit('setFilterHideClosed', value)
    return dispatch('loadSevenDayActivity')
  },
  setFilterCustomersOnly({commit,dispatch}, value) {
    commit('setFilterCustomersOnly', value)
    return dispatch('loadSevenDayActivity')
  },
  setFilterHideNoActivity({commit,dispatch}, value) {
    commit('setFilterHideNoActivity', value)
    return dispatch('loadSevenDayActivity')
  },
  setSelectedReportIndex({commit, dispatch}, value) {
    commit('setSelectedReportIndex', value)
    return dispatch('loadSevenDayActivity')
  },
  setSelectedDate({commit, dispatch}, date) {
    commit('setSelectedDate', date)
    return dispatch('loadSevenDayActivity')
  },
  setBaseCurrencyMode({commit, dispatch}, value) {
    commit('setBaseCurrencyMode', value)
    return dispatch('loadSevenDayActivity')
  },
  resetState({commit}) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}
