import api from '@/Api'
import mixins from '../../romaCommon.js'

const getDefaultState = () => {
  return {
    customerCashBalances: [],
    customerLedgerBalances: [],
    customerNetBalances: [],
    creditStatuses: ['A','AA','AAA','NA'],
    selectedDate: null,
    groupByCurrency: true,
    convertToBase: false,
    reportDate: null, 
    error: null,
  }
}

const state = getDefaultState();

const mutations = {
  setCustomerCashBalances(state, customerCashBalances) {
    state.customerCashBalances = customerCashBalances
  },
  setCustomerLedgerBalances(state, customerLedgerBalances) {
    state.customerLedgerBalances = customerLedgerBalances
  },
  setCustomerNetBalances(state, customerNetBalances) {
    state.customerNetBalances = customerNetBalances
  }, 
  setReportDate(state, reportDate) {
    state.reportDate = reportDate
  },
  setError(state, error) {
    state.error = error
  },
  clearError(state) {
    state.error = null
  },
  setSelectedDate(state, selectedDate) {
    state.selectedDate = selectedDate
  },
  setGroupByCurrency(state, groupByCurrency) {
    state.groupByCurrency = groupByCurrency
  },
  setConvertToBase(state, convertToBase) {
    state.convertToBase = convertToBase
  },
  setCreditStatus(state, creditStatuses) {
    state.creditStatuses = creditStatuses
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  reportDate: state => {
    return state.reportDate
  },
  customerCashBalancesRows: state => {
    if (!state.customerCashBalances) return []
    return state.customerCashBalances?.rows
  },
  customerLedgerBalancesRows: state => {
    if (!state.customerLedgerBalances) return []
    return state.customerLedgerBalances?.rows
  },
  customerNetBalancesRows: state => {
    if (!state.customerNetBalances) return []
    return state.customerNetBalances?.rows
  },
  selectedDate: state => {
    return state.selectedDate
  },
  groupByCurrency: state => {
    return state.groupByCurrency
  },
  convertToBase: state => {
    return state.convertToBase
  },
  queryParams: (state) => (format) => {
    return new URLSearchParams( {
      date: mixins.formatJsDate_localTZ(state.selectedDate),
      groupByCurrency: state.groupByCurrency,
      convertToBase: state.convertToBase,
      creditStatus: state.creditStatuses,
      ...(format && {format: format})
    })
  }
}

const actions = {
  async loadCustomerCashBalances({ state, commit, getters }) {
    if (!state.selectedDate) return
    commit('clearError')
    commit('setCustomerCashBalances', [])
    try {
      const response = await api.getCustomerCashBalances(getters.queryParams())
      console.log("Customer cash balance data loaded: ", response.data)
      commit('setCustomerCashBalances', response.data)
      commit('setReportDate', response.data.date)
    } catch(error) {
      console.error("getCustomerCashBalances:", error)
      commit('setError', { 
        message: "Failed to load Customer Cash Balances",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    }
  },
  async loadCustomerLedgerBalances({ commit, getters }) {
    commit('clearError')
    commit('setCustomerLedgerBalances', [])
    try {
      const response = await api.getCustomerLedgerBalances(getters.queryParams())
      console.log("Customer ledger balance data loaded: ", response.data)
      commit('setCustomerLedgerBalances', response.data)
      commit('setReportDate', response.data.date)
    } catch(error) {
      console.error("getCustomerLedgerBalances:", error)
      commit('setError', { 
        message: "Failed to load Customer Ledger Balances",
        reason: mixins.formatApiErrorMessage(error)
      })
    }
  },
  async loadCustomerNetBalances({ commit, getters }) {
    commit('clearError')
    commit('setCustomerNetBalances', [])
    try {
      const response = await api.getCustomerNetBalances(getters.queryParams())
      console.log("Customer ledger net data loaded: ", response.data)
      commit('setCustomerNetBalances', response.data)
      commit('setReportDate', response.data.date)
    } catch(error) {
      console.error("getCustomerNetBalances:", error)
      commit('setError', { 
        message: "Failed to load Customer Net Balances",
        reason: this.formatApiErrorMessage(error)
      })
    }
  },
  loadAll({dispatch}) {
    dispatch('loadCustomerCashBalances')
    dispatch('loadCustomerLedgerBalances')
    dispatch('loadCustomerNetBalances')
  },
  setSelectedDate({ commit, dispatch }, date) {
    commit('setSelectedDate', date)
    dispatch('loadAll')
  },
  resetState({commit}) {
    commit('resetState')
  },
  setGroupByCurrency( {commit, dispatch }, value) {
    commit('setGroupByCurrency', value)
    dispatch('loadAll')
  },
  setConvertToBase( {commit, dispatch }, value) {
    commit('setConvertToBase', value)
    dispatch('loadAll')
  },
  setCreditStatus( {commit, dispatch }, value) {
    commit('setCreditStatus', value)
    dispatch('loadAll')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}
